import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CRMLeadServiceService } from '../leadAPI/crmlead-service.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _CRMLeadService : CRMLeadServiceService,
             private _router : Router){  }

  canActivate(): boolean {
    if(this._CRMLeadService.loggedIn()){
      return true;
    }
    else{
      this._router.navigate([''])
      return false;
    }
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { observable, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { email } from 'src/app/Interface/email';
import { IAddService } from 'src/app/Interface/IAddService';
import { IGetLeads } from 'src/app/Interface/IGetLeads';
import { IGetServices } from 'src/app/Interface/IGetService';
import { ILead } from 'src/app/Interface/ILead';
import { ILogin } from 'src/app/Interface/ILogin';
import { IToken } from 'src/app/Interface/IToken';

@Injectable({
  providedIn: 'root'
})
export class CRMLeadServiceService {

  private apiLink: string = environment.production;

  constructor(private http : HttpClient) {
   }

  getTokenLogin(login: ILogin) : Observable<IToken>{
    console.log(login)
    let tempVar = this.http.post<IToken>(this.apiLink+"auth/login",login).pipe(catchError(this.errorHandler));
    console.log(tempVar);
    return tempVar;
  }


  addLead(lead: any) : Observable<string>{
    console.log(lead);
    let tempVar = this.http.post<string>(`${this.apiLink}lead`,lead).pipe(catchError(this.errorHandler));
    return tempVar;
  }

  
  //#region : Fetching Leads via API
  getLeads() : Observable<IGetLeads[]>{
    let tempVar = this.http.get<IGetLeads[]>(`${this.apiLink}lead`).pipe(catchError(this.errorHandler));
    console.log(tempVar)
    return tempVar;
  }
  //#endregion
  //#region : Fetching Leads via API
  getSingleLead(leadId : string) : Observable<any>{
    let tempVar = this.http.get<any>(`${this.apiLink}lead/${leadId}`).pipe(catchError(this.errorHandler));
    return tempVar;
  }
  //#endregion

  //Pagination lead calling
  getSingleLeadPagination(take : number, skip: number) : Observable<any>{
    let tempVar = this.http.get(this.apiLink + `lead/branch/${localStorage.getItem('branchId')}/userSpecific?take=${800}&skip=${0}`).pipe(catchError(this.errorHandler));
    console.log(tempVar)
    return tempVar;
  }
  //pagination lead calling end


  //#region  get services
  getServices():Observable<IGetServices[]>{
    let tempVar = this.http.get<IGetServices[]>(`${this.apiLink}service`).pipe(catchError(this.errorHandler));
    return tempVar;
  }
  //#endregion

  //#region addSerice
  addService(serviceName: string, charges: string, countryCode:number){
    let obj : IAddService = {
      serviceName : serviceName,
      charges : charges,
      serviceType: 1,
      branchId: 1,
      countryId: countryCode,
      isUniversal: true
    }
    let tempVar = this.http.post<IAddService>(`${this.apiLink}service`,obj)
    return tempVar
  }
  //#endregion
  getTokenFromStorage(){
    return localStorage.getItem('token')
  }

  loggedIn(){
    return !!localStorage.getItem("token")
  }

  sendEmailTOUser(email : email){
    return this.http.post("http://localhost:9000/sendmail",email);
  }



  //Get per lead services
  getLeadServices(id):Observable<any>{
    var k = this.http.get<any>(this.apiLink+"service/lead/"+id)
    return k;
  }
  //End get per lead services

  //Add user another service
  demo = {
    "leadId":89,
    "serviceId":10,
    "accountUserId": 1,
    "serviceMilestoneId":1,
    "active":true,
    "isSoftDelete":"0"
  }
  
  addLeadServices(id):Observable<any>{
    return this.http.post<any>(this.apiLink+"lead/assignService", this.demo)
  }

  // {
  //   "leadId":89,
  //   "serviceId":12,
  //   "accountUserId": 1,
  //   "serviceMilestoneId":1
  // }
  
  //end add user another service

  baseApiUrl = "https://file.io"
  
  // Returns an observable
  upload(file):Observable<any> {
  
      // Create form data
      const formData = new FormData(); 
        
      // Store form name as "file" with file data
      formData.append("file", file, file.name);
        
      // Make http post request over api
      // with formData as req
      return this.http.post(this.baseApiUrl, formData)
  }


  //studyGap section

  getStudyGap(leadId : string): any{
    return this.http.get<any>(this.apiLink+"document/studyGap/lead/"+leadId)
  }


  postStudyGap( obj : FormData){
    return this.http.post(this.apiLink+"document/studyGap", obj)
  }


  //chat section
  getChat(lead : string): Observable<any>{
    return this.http.get(this.apiLink+`lead-chat?leadId=${lead}&take=400&skip=0`)
  }

  postChat(obj : any){
    return this.http.post(this.apiLink+"lead-chat",obj)
  }

  postChatDocument(obj : any){
    return this.http.post(this.apiLink+"lead-chat/document",obj)
  }

  getNotification(){
    return this.http.get(this.apiLink+'lead-chat/notification');
  }

  clearNotification(leadId : string){
    return this.http.post(this.apiLink+'lead-chat/readNotificationSignal', {
      leadId:leadId
    });
  }
  
  //Accounts section
  createAccount(obj : any){
    return this.http.post(this.apiLink+'account', obj);
  }

  getAccounts(){
    return this.http.get(this.apiLink+'account')
  }

  //Branches Section
  createBranch(obj : any){
    return this.http.post(this.apiLink+`account/${localStorage.getItem('accountId')}/branch`,obj)
  }

  getBranches(accountId : string){
    return this.http.get<any>(this.apiLink+`account/${accountId}/branch`)  
  }


  //user section
  createUser(obj : any, accountId:  string, branchId:string){
    return this.http.post(this.apiLink+`account/${accountId}/branch/${branchId}/accountUser`, obj)
  }

  getUser(branchId){
    return this.http.get(this.apiLink+`account/accountUser/branch/${branchId}`).pipe(catchError(this.errorHandler));
  }

  //passportUpload
  createPassport(obj : any){
    return this.http.post(this.apiLink+"document/passport", obj)
  }

  getPassport(leadId : any){
    return this.http.get(this.apiLink+"document/passport/lead/"+leadId)
  }

  //IeltsPteUpload
  createIeltsPte(obj : any){
    return this.http.post(this.apiLink+"document/ieletsScore", obj)
  }

  getIeltsPte(leadId : string){
    return this.http.get(this.apiLink+"document/ieletsScore/leadId/"+leadId)
  }

  //download pdf
  downloadPdf(documentId : string){
    return this.http.get(this.apiLink+"document/"+documentId);
  }



  //Education upload API's
  getEducation(leadId : string){
    return this.http.get(this.apiLink+"document/educationHistory/leadId/"+leadId)
  }

  postEducation(obj : any){
    return this.http.post(this.apiLink+"document/education", obj);
  }


  getLeadsForAdminOnBranch(branchId: string)  : Observable<any>{
    return this.http.get(this.apiLink + `lead/branch/${branchId}/userSpecific?take=${800}&skip=${0}}`)
  }


  //post employment
  postEmploment(obj :FormData){
    return this.http.post(this.apiLink+"document/employment", obj);
  }

  getEmployment(leadId: string){
    return this.http.get(this.apiLink+"document/employment/lead/"+leadId)
  }

  //Sponsor Visa API's

  //Sponsor 

  postSponsorData(obj : FormData){
    return this.http.post(this.apiLink+"document/sponser", obj);
  }

  getSponsorData(leadId : string) : Observable<any>{
    return this.http.get<Observable<any>>(this.apiLink+"document/sponser/lead/"+leadId);
  }

  putLeadStatus(leadId: string, status : string){
    return this.http.patch(this.apiLink+`lead/${leadId}/status/${status}`, {})
  }

  getMilestones(serviceId : string){
    return this.http.get(this.apiLink+`service/${serviceId}/milestones`)
  }

  putMilestones(leadToServiced : string, milestoneSequenceNumber, mileStoneSequenceId){
    return this.http.patch(this.apiLink+`service/leadToService/${leadToServiced}/milestoneSequence/${mileStoneSequenceId}/milestoneSequenceNumber/${milestoneSequenceNumber}`, {})
  }


  //Invoice API"s

  //getLeadToServiceCharges
  getLeadToServiceCharges(leadToServiceId : string){
    return this.http.get(this.apiLink+`lead-to-service-charges/${leadToServiceId}`).pipe(catchError(this.errorHandler))
  }

  putDiscountNetPayable(obj : any){
    return this.http.patch(this.apiLink+`lead-to-service-charges/`,obj)
  }

  postInstallment(obj){
    return this.http.patch(this.apiLink+`lead-to-service-charges/installment`, obj).pipe(catchError(this.errorHandler))
  }


  //CollegeApi's
  postCollege(obj){
    return this.http.post(this.apiLink+"college", obj).pipe(catchError(this.errorHandler));
  }

  getCollege():any{
    return this.http.get(this.apiLink+"college").pipe(catchError(this.errorHandler));
  }

  //CourseApi's
  postCourse(obj){
    return this.http.post(this.apiLink+"course", obj).pipe(catchError(this.errorHandler));
  }

  getCourse(taken, skipped){
    return this.http.get(this.apiLink+`course?take=${taken}&skip=${skipped}`).pipe(catchError(this.errorHandler));
  }

  getCourseOfCollege(taken, skipped, collegeId){
    return this.http.get(this.apiLink+`course/findCollegeCourses?take=${taken}&skip=${skipped}&collegeId=${collegeId}`).pipe(catchError(this.errorHandler));
  }

  getIntakeOfCourse(courseId){
    return this.http.get(this.apiLink+`course/${courseId}/allActiveAndPossibleCourseIntakes`).pipe(catchError(this.errorHandler));
  }

  //courseIntakeAPI
  postIntake(obj):any{
    return this.http.post(this.apiLink+"course/courseIntake", obj).pipe(catchError(this.errorHandler));
  }

  getIntakes(){
    return this.http.get(this.apiLink+"course/allIntakes").pipe(catchError(this.errorHandler));
  }

  addintakeOfCourse(intakeId, courseId){
    return this.http.post(this.apiLink+"course/activateCourse",{
      courseId:courseId,
      courseIntakeId:intakeId
    })
  }

  removeIntakeOfCourse(courseToCourseIntakeId){
    return this.http.delete(this.apiLink+`course/courseToCourseIntake/${courseToCourseIntakeId}`)
  }

  postLeadApplicationToCourse(leadId, courseId){
    return this.http.post(this.apiLink+"lead-application", {
      leadId:leadId,
      courseToCourseIntakeId:courseId
    })
  }

  getLeadApplications(leadId){
    return this.http.get(this.apiLink+`lead-application/leadId/${leadId}`)
  }

  // Teams api's
  postTeam(teamName : string, branchId : string){
    return this.http.post(this.apiLink+"teams",{teamName: teamName, branchId:branchId});
  }

  getTeams(branchId){
    return this.http.get(this.apiLink+`teams/branch/${branchId}`)
  }

  addUserToTeam(obj : any){
    return this.http.post(this.apiLink + `teams/${obj.teamId}/addAccountUser`, obj);
  }

  addServiceToTeam(obj : any){
    return this.http.post(this.apiLink+`teams/${obj.teamId}/assignService`, obj);
  }

  getUsersToTeam(teamId: string){
    return this.http.get(this.apiLink+`teams/${teamId}/teamAccountUsers`);
  }

  getAddedAndUnaddedUsersOfTeam(teamId:string){
    return this.http.get(this.apiLink + 'teams/' +teamId+'/teamAddableAccountUsers');
  }

  getAddableServicesOfTeam(teamId: string){
    return this.http.get(this.apiLink + 'teams/' +teamId+'/teamAddableServices');
  }

  getServicesToTeam(teamId:string){
    return this.http.get(this.apiLink+`teams/${teamId}/teamServices`);
  }

  deleteUserFromTeam(obj:any){
    return this.http.post(this.apiLink+`teams/${obj.teamId}/deleteAccountUserFromTeam`, obj);
  }

  deleteServiceFromTeam(obj:any){
    return this.http.post(this.apiLink+`teams/${obj.teamId}/deleteServiceFromTeam`, obj);
  }
  // End Teams api's

  //sendwatsappMessage
  sendWatsapp(numberToSendMessage: string, msg: string){
    return this.http.post(`https://fullstackmtech.com/api/send.php?number=${numberToSendMessage}&type=text&message=${msg}&instance_id=641AA7314A6AA&access_token=1f2b95c8ead70b3e2d3816fcf2ffb6e9`, {})
  }

  //#region rating
  updateRating(leadId : string, rating: number){
    return this.http.patch(this.apiLink+`lead/${leadId}/rating/${rating}`,{});
  }
  //#endregion

  //#region updateLeads
  updateNotes(notes:string, leadId:string){
    return this.http.patch(this.apiLink+`lead/${leadId}`,{
      notes : notes
    }).pipe(catchError(this.errorHandler));
  }
  //#endregion

  //#region Tasks
  createTask(obj : any){
    return this.http.post(this.apiLink + "agile/agileTask", obj).pipe(catchError(this.errorHandler));
  }

  getTask(){
    return this.http.get(this.apiLink + "agile/agileTask").pipe(catchError(this.errorHandler));
  }

  changeTaskStatus(obj: any, id:string){
    return this.http.patch(this.apiLink + `agile/agileTask/${id}`, obj).pipe(catchError(this.errorHandler));
  }
  //#endregion

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || "Server Error");
  } 
  
}

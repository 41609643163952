import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ILogin } from 'src/app/Interface/ILogin';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-landing-new',
  templateUrl: './landing-new.component.html',
  styleUrls: ['./landing-new.component.scss']
})
export class LandingNewComponent implements OnInit{
  privacy: boolean;
  tAndC: boolean;
  refund: boolean;
  width: number;
  viewLoader: boolean = true;
  status: any;
  errMessage: any;
  isOsyncs:any = false;
  isLoaded: boolean = false;

  constructor(private _crmLeadService: CRMLeadServiceService, private _router : Router) {
    const currentDomain: string = window.location.href;
    //const currentDomain: string = "osyncs.com";
    this.isOsyncs = currentDomain.includes('osyncs') || currentDomain.includes('Osyncs')
  }
  ngOnInit(): void {
    if(!!localStorage.getItem('token')){
      this._router.navigate(['/user'])
    }
    this.width = window.innerWidth
    //console.log(this.width)
  }
 
 submitform(form : NgForm){
  this.viewLoader = false;
  let login : ILogin;
  login = {username: form.value.email, password : form.value.password};
  this._crmLeadService.getTokenLogin(login).subscribe(
    responseStatus =>{
      this.status = responseStatus;   
    },
    responseError =>{
      this.viewLoader = true;
      this.errMessage = responseError;
      alert("Please try with correct password or reach out to the support.")
    },
    ()=>{
      console.log(this.status)

      let token = this.status.access_token;

      localStorage.setItem('user', this.status.user.userEmail)
      localStorage.setItem('userId', this.status.user.id)
      localStorage.setItem('branchId', this.status.user.branch.id)
      localStorage.setItem('accountId', this.status.user.branch.Account.id)
      localStorage.setItem('role', this.status.user.role)
      localStorage.setItem('canViewAllBranch', this.status.user?.permission?.canViewAllBranch)
      localStorage.setItem('canViewAdminPanel', this.status.user?.permission?.canViewAdminPanel)
      localStorage.setItem('canResetWhatsapp', this.status.user?.permission?.canResetWhatsapp)
      localStorage.setItem('canViewSuperAdmin', this.status.user?.permission?.canViewSuperAdmin)
      localStorage.setItem('canDeleteLead', this.status.user?.permission?.canDeleteLead)
      localStorage.setItem('canCreateBranch', this.status.user?.permission?.canCreateBranch)
      this.viewLoader = true;
      if(token){
        localStorage.setItem('token',token);
        document.getElementById("closeButton").click();
        setTimeout(() => {
          this._router.navigate(['/user/dashboard']);
        }, 100);
      }
      else{
        alert("Please enter the correct passowrd")
      }
    }
  )
 }

  selectPolicy(policyName: string){
    if(policyName == "refund"){
      this.privacy = false;
      this.tAndC = false;
      this.refund = true;
    }
    else if(policyName == "privacy"){
      this.privacy = true;
      this.tAndC = false;
      this.refund = false;
    }
    else{
      this.privacy = false;
      this.tAndC = true;
      this.refund = false;
    }
   }

  getDomainFromFQDN(fqdn) {
    // Split the FQDN by periods (.)
    const parts = fqdn.split('.');
  
    // Check if there are at least two parts (e.g., 'example.com')
    if (parts.length >= 2) {
      // Extract the last two parts (main domain and TLD)
      const domain = parts.slice(-2).join('.');
      return domain;
    } else {
      // FQDN doesn't follow the expected format
      return null;
    }
  }
  
  onImageLoad(){
    this.isLoaded = true;
    console.log("Rendered")
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './Services/AuthGuard/auth.guard';
import { LoginComponent } from './starter/login/login.component';
import { PrivacyPolicyComponent } from './starter/Policies/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './starter/Policies/refund-policy/refund-policy.component';
import { TermsAndConditionsComponent } from './starter/Policies/terms-and-conditions/terms-and-conditions.component';
import { LandingNewComponent } from './starter/landing-new/landing-new.component';


const routes: Routes = [
  {
    path:'',
    component:LandingNewComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule),
    canActivate :[AuthGuard]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
